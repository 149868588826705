import React, { useEffect, useState } from "react";
import axios from "axios";

export default function RejectedcontactList() {
  const [rejectedRequests, setRejectedRequests] = useState([]);

  useEffect(() => {
    const fetchRejectedRequests = async () => {
      try {
        const response = await axios.post(
          "https://api.savitarpms.com/api/get-ContactUs",
          {
            status: false,
          }
        );
        setRejectedRequests(response.data.data);
        console.log("Rejected contact requests fetched:", response.data);
      } catch (error) {
        console.error("Error fetching rejected contact requests:", error);
      }
    };

    fetchRejectedRequests();
  }, []);

  return (
    <div>
      <h2 className=" flex text-2xl font-bold justify-center mb-5">Rejected Contact Requests</h2>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ padding: "8px", border: "5px solid #000" }}>No.</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              First Name
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Last Name
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>Email</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>Phone</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Country
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Message
            </th>
          </tr>
        </thead>
        <tbody>
          {rejectedRequests.map((request, index) => (
            <tr key={request._id}>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {index + 1}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.firstName}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.lastName}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.email}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.phone}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.country}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.message}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
