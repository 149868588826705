import React, { useState } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitSendOTP = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await axios.post(
        "https://api.savitarpms.com/api/SendOtp",
        formData
      );
      console.log("Response:", response);
      if (response.status === 200) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const ResendOTP = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await axios.post(
        "https://api.savitarpms.com/api/SendOtp",
        formData
      );
      console.log("Response:", response);
      if (response.status === 200) {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(
        "https://api.savitarpms.com/api/admin/verify-otp-handler",
        {
          email: formData.email,
          otp: otpValue,
        }
      );

      if (response.status === 200) {
        console.log("Email verified successfully.");
        localStorage.setItem("email", formData.email);
        alert("OTP Successfully.");

        setShowPopup(false);

        navigate("/confirmationpassword");
      } else {
        // alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Invalid OTP. Please try again.");
    }
  };

  const resetPassword = async () => {
    try {
      const response = await axios.post(
        "https://api.savitarpms.com/api/reset-password",
        formData
      );
      if (response.status === 200) {
        console.log("Password reset successful.");
      } else {
        console.log("Error resetting password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  return (
    <main id="content" role="main" className="w-full max-w-md mx-auto p-2">
      <div className="mt-64 bg-white rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700 border-2 border-indigo-300">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
              Forgot password?
            </h1>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Remember your password?
              <a
                className="text-blue-600 decoration-2 hover:underline font-medium"
                href="#"
              >
                Login here
              </a>
            </p>
          </div>

          <div className="mt-5">
            <form onSubmit={handleSubmitSendOTP}>
              <div className="grid gap-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold ml-1 mb-2 dark:text-white"
                  >
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                      required
                    />
                  </div>
                  <p
                    className="hidden text-xs text-red-600 mt-2"
                    id="email-error"
                  >
                    Please include a valid email address so we can get back to
                    you
                  </p>
                </div>
                <button
                  type="submit"
                  className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                >
                  Reset password
                </button>
              </div>
            </form>

            {showPopup && (
              <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
                <div className="bg-black bg-opacity-80 absolute inset-0"></div>
                <div className="relative">
                  <div className="bg-sky-900 p-8 rounded-lg shadow-lg flex flex-col items-center">
                    <input
                      type="text"
                      value={otpValue}
                      onChange={(e) => setOtpValue(e.target.value)}
                      placeholder="Enter OTP"
                      className="m-10 p-3 text-lg text-center bg-white font-bold rounded-md"
                    />
                    <div className="flex justify-center mb-4">
                      <button
                        onClick={handleVerifyOTP}
                        className="bg-blue-500 text-white rounded-md p-2 mr-4"
                      >
                        Verify
                      </button>

                      <button
                        className="bg-red-500 text-white rounded-md p-2 mr-2"
                        onClick={handlePopupClose}
                      >
                        Close
                      </button>
                      <button
                        onClick={ResendOTP}
                        className="bg-black  text-white rounded-md p-2 "
                      >
                        Resend OTP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <p className="mt-3 flex justify-center items-center text-center divide-x divide-gray-300 dark:divide-gray-700">
        <a
          className="pr-3.5 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200"
          href="#"
          target="_blank"
        >
          <svg
            className="w-3.5 h-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
          </svg>
          View Github
        </a>
        <a
          className="pl-3 inline-flex items-center gap-x-2 text-sm text-gray-600 decoration-2 hover:underline hover:text-blue-600 dark:text-gray-500 dark:hover:text-gray-200"
          href="#"
        >
          Contact us!
        </a>
      </p>
    </main>
  );
};

export default ForgotPassword;
