import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AdminModal from "./AdminModal";
import AdminSidebar from "./admin-sidebar";

function Adminpanel() {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isLogin");
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <AdminSidebar>
        <Outlet />
      </AdminSidebar>
    </div>
  );
}

export default Adminpanel;
