import axios from "axios";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory, useNavigate } from "react-router-dom"; // Import useHistory for navigation

const Admin_confirmationpassword = () => {
  const iconStyle = {
    width: "1.5rem",
    height: "1.5rem",
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem("email");
    if (email === undefined) {
      navigate("/");
    } else if (password === confirmPassword) {
      const response = await axios.post(
        "https://api.savitarpms.com/api/admin/reset-password",
        {
          email: email,
          password: password,
        }
      );
      if (response.status === 200) {
        navigate("/resetpasswordsuccess");
      } 
    } else {
      setValidationMessage("Password does not match!");
    }
  };

  return (
    <section className="grid h-screen place-content-center bg-slate-900 text-slate-300">
      <div className="mb-10 text-center text-indigo-400">
        <h1 className="text-3xl font-bold tracking-widest">React</h1>
        <p>
          <span className="font-bold">Password</span> and{" "}
          <span className="font-bold">Confirm</span> validation.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col items-center justify-center space-y-6">
          <input
            type={passwordType}
            id="password"
            name="password"
            placeholder="Password"
            className="w-80 appearance-none rounded-full border-0 bg-slate-800/50 p-2 px-4 focus:bg-slate-800 focus:ring-2 focus:ring-orange-500"
            value={password}
            required
            onChange={handlePasswordChange}
          />
          <div className="relative">
            <input
              type={passwordType}
              required
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
              className="w-80 appearance-none rounded-full border-0 bg-slate-800/50 p-2 px-4 focus:bg-slate-800 focus:ring-2 focus:ring-orange-500"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <div
              id="showPw"
              className="absolute top-12 right-72 p-1 text-white cursor-pointer"
              onClick={togglePasswordVisibility}
              style={{
                borderRadius: "0.25rem",
              }}
            >
              {passwordType === "password" ? (
                <FaEye style={iconStyle} />
              ) : (
                <FaEyeSlash style={iconStyle} />
              )}
            </div>
          </div>
          <p
            id="validation"
            className="text-center text-orange-500 italic text-sm"
          >
            {validationMessage}
          </p>
          <button
            type="submit"
            className="rounded-full bg-indigo-500 p-2 px-4 text-white hover:bg-orange-500"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default Admin_confirmationpassword;
