import React from 'react';
import { Link } from 'react-router-dom';

const ResetPasswordSuccess = () => {
  return (
    <section>
      <main id="content" role="main" className="w-full h-screen max-w-xl p-6 mx-auto">
        <div className="py-12 bg-white border shadow-lg mt-7 rounded-xl">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <div className="flex items-end justify-center mb-12 text-2xl font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 text-indigo-600 fill-current">
                  <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12"></path>
                </svg>
                focus<span className="text-indigo-600">.co</span>
              </div>
              <h1 className="block mb-2 text-xl font-bold text-gray-800">Successful Password Reset!</h1>
              <p className="mb-12 text-gray-800">You can now use your new password to log in to your account! 🙌</p>
            </div>
            <div className="px-4 mx-auto text-center sm:px-7">
              <Link
              to={"/"}
                type="button"
                className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default ResetPasswordSuccess;
