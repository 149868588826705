import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

export default function ApprovalModal({ isOpen, onClose, selectedRequest }) {
  const [contactRequests, setContactRequests] = useState([]);

  const fetchContactRequests = async () => {
    try {
      const response = await axios.post(
        "https://api.savitarpms.com/api/get-ContactUs",
        {
          status: true,
        }
      );
      setContactRequests(response.data.data);
      console.log("Contact requests fetched:", response.data);
    } catch (error) {
      console.error("Error fetching contact requests:", error);
    }
  };
  useEffect(() => {
    fetchContactRequests();
  }, []);

  return (
    <div>
      <div className="modal-backdrop">
        <div className="modal-content">
          <h2 className="font-bold text-2xl  mb-5 justify-center flex">Approval Contact Request</h2>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  No.
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  First Name
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  Last Name
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  Email
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  Phone
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  Country
                </th>
                <th style={{ padding: "8px", border: "5px solid #000" }}>
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {contactRequests.map((request, index) => (
                <tr key={request._id}>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {index + 1}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.firstName}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.lastName}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.email}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.phone}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.country}
                  </td>
                  <td style={{ padding: "8px", border: "2px solid #000" }}>
                    {request.message}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
