import React, { useState, useEffect } from "react";
import axios from "axios";

function AdminModal() {
  const [contactRequests, setContactRequests] = useState([]);

  const fetchContactRequests = async () => {
    try {
      const response = await axios.post(
        "https://api.savitarpms.com/api/get-ContactUs"
      );
      setContactRequests(response.data.data);
      console.log("Contact requests fetched:", response.data);
    } catch (error) {
      console.error("Error fetching contact requests:", error);
    }
  };

  useEffect(() => {
    fetchContactRequests();
  }, []);

  const handleApprove = async (id) => {
    try {
      console.log("Approve button clicked for ID:", id);
      const response = await axios.put(
        `https://api.savitarpms.com/api/approve-contact/${id}`
      );
      console.log("Approval response:", response.data);

      setContactRequests((prevRequests) =>
        prevRequests.filter((request) => request._id !== id)
      );
    } catch (error) {
      console.error("Error approving contact request:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      console.log("Reject button clicked for ID:", id);
      const response = await axios.put(
        `https://api.savitarpms.com/api/reject-contact/${id}`
      );
      console.log("Rejection response:", response.data);

      setContactRequests((prevRequests) =>
        prevRequests.filter((request) => request._id !== id)
      );
    } catch (error) {
      console.error("Error rejecting contact request:", error);
    }
  };

  return (
    <div>
      <h1 className="mb-5 justify-center flex text-2xl font-bold">
        New Contact Requests
      </h1>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ padding: "8px", border: "5px solid #000" }}>No.</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              First Name
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Last Name
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>Email</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>Phone</th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Country
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>
              Message
            </th>
            <th style={{ padding: "8px", border: "5px solid #000" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {contactRequests.map((request, index) => (
            <tr key={request._id}>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {index + 1}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.firstName}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.lastName}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.email}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.phone}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.country}
              </td>
              <td style={{ padding: "8px", border: "2px solid #000" }}>
                {request.message}
              </td>
              <td
                style={{
                  padding: "8px",
                  border: "2px solid #000",
                  textAlign: "center",
                }}
              >
                <button
                  className="bg-green-600 p-2 rounded-xl font-bold"
                  style={{ marginRight: "8px" }}
                  onClick={() => handleApprove(request._id)}
                >
                  Approve
                </button>
                <button
                  className="bg-red-700 p-2 rounded-xl font-bold"
                  style={{ marginRight: "8px" }}
                  onClick={() => handleReject(request._id)}
                >
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminModal;
