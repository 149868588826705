

import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { PowerIcon, PresentationChartBarIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Outlet, useNavigate } from "react-router-dom";
import ApprovalModal from "./ApprovalModal";

const AdminSidebar = ({ children }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to log out?");
    if (isConfirmed) {
      localStorage.clear("isLogin");
      navigate("/");
    }
  };

  return (
    <div className="flex">
      <Card className="h-screen w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 rounded-none fixed left-0 top-0 bg-gray-300">
        <div className="mb-2 p-4">
          <Typography variant="h5" color="blue-gray">
            Sidebar
          </Typography>
        </div>
        <List className="space-y-0">
          <ListItem onClick={handleOpen}>
            <ListItemPrefix>
              <PresentationChartBarIcon className="h-10 w-5" />
            </ListItemPrefix>
            <Typography color="blue-gray" className="mr-auto font-normal">
              Dashboard
            </Typography>
            <ChevronRightIcon className={`h-5 w-5 transition-transform ${open ? 'transform rotate-90' : ''}`} />
          </ListItem>
          {open && (
            <List>
              <ListItem onClick={() => navigate("new-contact")} className="flex items-center mb-3 bg-gray-500 text-white ">
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-8 w-5" />
                </ListItemPrefix>
                New Contact Request
              </ListItem>
              <ListItem onClick={() => navigate("approval-contact")} className="flex items-center mb-3 bg-gray-500 text-white">
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-8 w-5 " />
                </ListItemPrefix>
                Approval Contact Request
              </ListItem>
              <ListItem onClick={() => navigate("rejected-contact")} className="flex items-center mb-2 bg-gray-500 text-white">
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-8 w-5" />
                </ListItemPrefix>
                Rejected Contact Request
              </ListItem>
            </List>
          )}
          <ListItem onClick={() => navigate("profile")} className="flex items-center">
            <ListItemPrefix>
              <UserCircleIcon className="h-10 w-5" />
            </ListItemPrefix>
            Profile
          </ListItem>
        </List>
        <List className="mt-auto">
          <ListItem onClick={handleLogout} className="flex items-center bg-gray-600 text-white
          ">
            <ListItemPrefix>
              <PowerIcon className="h-10 w-5" />
            </ListItemPrefix>
            Log Out
          </ListItem>
        </List>
      </Card>
      <div className="w-full p-8 ml-[20rem]">{children}</div>
    </div>
  );
};

export default AdminSidebar;
