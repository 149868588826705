import React from "react";
import Login from "./Components/login";
import Adminpanel from "./Admin/Adminpanel";
import AdminModal from "./Admin/AdminModal";
import ApprovalModal from "./Admin/ApprovalModal";
import RejectedcontactList from "./Admin/RejectedcontactList";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import ForgotPassword from "./Components/ForgotPassword";
import Admin_confirmationpassword from "./Components/Admin_confirmationpassword";
import ResetPasswordSuccess from "./Components/ResetPasswordSuccess";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin-savitara-infotel" element={<Adminpanel />}>
          <Route path="new-contact" element={<AdminModal />} />
          <Route path="approval-contact" element={<ApprovalModal />} />
          <Route path="rejected-contact" element={<RejectedcontactList />} />
        </Route>

        <Route path="/" element={<Login />}>
          <Route index element={<Login />} />
          <Route path="*" />
        </Route>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/confirmationpassword" element={<Admin_confirmationpassword/>}/>
        <Route path="/resetpasswordsuccess" element={<ResetPasswordSuccess/>}/>
      </Routes>
    </BrowserRouter>
  );
}
